@font-face {
  font-family: 'MaestroArabic';
  src: url('./fonts/MaestroArabic-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cervo';
  src: url('./fonts/Cervo-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}